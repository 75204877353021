import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { FaStar, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'

import Image from "../../../components/Image";

const SectionTestimonial = ({ data }) => {
    var items
    if (!data || data.length === 0) {
        items = []
    } else {
        items = data
    };

    return (
        <Container>
            <Row>
                <Col>
                    {/* <!-- Section heading --> */}
                    <h2 className="h1-responsive font-weight-bold mb-5 text-center">Testimonials</h2>
                </Col>
            </Row>

            {/* <!-- Section description -->
             <p className="dark-grey-text w-responsive mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur veniam.</p>
            */}

            {/* <!-- Grid row --> */}
            <div className="row">
                {/* TESTIMONIAL CARDS */}
                {items.map(data => (
                    <div className="col-md-4 mb-4">
                        {/* <!--Card--> */}
                        <div className="card testimonial-card text-center">
                            {/* <!--Background color--> */}
                            <div className="card-up"></div>
                            {/* <!--Avatar--> */}
                            <div className="avatar mx-auto white">
                                <Image
                                    className="rounded-circle img-fluid"
                                    src={data.img}
                                    alt="testimonial"
                                />
                            </div>
                            <div className="card-body">
                                {/* <!--Name--> */}
                                <h4 className="h4 font-weight-bold">{data.name}</h4>
                                <i>{data.desc}</i>
                                <hr />
                                {/* <!--Rating--> */}
                                <IconContext.Provider value={{ className: "text-warning" }}>
                                    <div className="rating-wrapper">
                                        <FaStar className="mr-1" />
                                        <FaStar className="mr-1" />
                                        <FaStar className="mr-1" />
                                        <FaStar className="mr-1" />
                                        <FaStar className="mr-1" />
                                    </div>
                                </IconContext.Provider>
                                {/* <!--Quotation--> */}
                                <p className="dark-grey-text mt-2 h6">
                                    <FaQuoteLeft className="pr-2" />
                                    {data.testi}
                                    <FaQuoteRight className="pl-2" />
                                </p>
                            </div>
                        </div>
                        {/* <!--Card--> */}
                    </div>
                ))}
            </div>
            {/* <!-- Grid row --> */}
        </Container>
    )
};

export default SectionTestimonial;